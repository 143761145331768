import React from 'react';
import { Container, Row } from 'react-bootstrap';

import './pagination.scss';

export const Pagination = (props) => {
  const {current, totalPages, hasNextPage, hasPreviousPage, basePath} = props;
  const paginationButtons = [];

  for (let x = -2; x < 3; x++) {
    if ((current + x) > 0 && (current + x) <= totalPages) {
      paginationButtons.push({
        text: current + x,
        href: current + x === 1 ? `${basePath}/` : `${basePath}/${current + x}`,
        active: x === 0
      });
    }
  }

  return (
    <div className="pagination-module">
      <Container>
        <Row className="row justify-content-center">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              {
                hasPreviousPage ?
                  <li className="page-item">
                    <a className="page-link" href={`${basePath}/${current - 1 === 1 ? '' : current - 1}`}>
                      Previous
                    </a>
                  </li> : ''
              }
              {
                paginationButtons.map((button, index) => (
                  <li key={index} className={`page-item ${button.active ? 'active disabled' : ''}`}>
                    <a className="page-link" href={button.href}>
                      {button.text}
                    </a>
                  </li>
                ))
              }
              {
                hasNextPage ?
                  <li className="page-item">
                    <a className="page-link" href={`${basePath}/${current + 1}`}>
                      Next
                    </a>
                  </li> : ''
              }
            </ul>
          </nav>
        </Row>
      </Container>
    </div>
  );
};
