import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faPhoneAlt,
  faShoppingBasket,
} from '@fortawesome/free-solid-svg-icons';

import BasketContext from '../../context/basket-context';
import { BASKET_CONSTANTS } from '../../shared/constants/basket-constants';

export const downloadButton = (downloadUrl) => (
  <Col>
    <a
      href={downloadUrl}
      target="_blank"
      className="btn btn-primary btn-block h-100 grow"
    >
      <FontAwesomeIcon icon={faDownload} className="fa" />
      Download
    </a>
  </Col>
);

export const callToOrderButton = () => (
  <Col>
    <a
      href="https://www.duluxtradepaintexpert.co.uk/en/advice/contact-us"
      target="_blank"
      className="btn btn-secondary btn-block grow"
    >
      <FontAwesomeIcon icon={faPhoneAlt} className="fa" />
      Call to Order
    </a>
  </Col>
);

export const fromStockButton = (product) => {
  const { dispatch } = useContext(BasketContext);

  const addItemHandler = () => {
    dispatch({
      type: BASKET_CONSTANTS.ADD_ITEM,
      payload: product,
    });
  };

  return (
    <Col>
      <button
        className="btn btn-secondary btn-block grow"
        onClick={() => addItemHandler()}
      >
        <FontAwesomeIcon icon={faShoppingBasket} className="fa" />
        Add to Basket
      </button>
    </Col>
  );
};
