import React from 'react';
import { Container, Row } from 'react-bootstrap';

import { Header } from '../../layout/header';
import { Footer } from '../../layout/footer';
import { HeroPrimary } from '../../components/hero-primary';
import { Filter } from '../../components/filter';
import { DuluxCard as Card } from '../../components/cards';
import { Pagination } from '../../components/pagination';
import { Logos } from '../../components/logos';
import SEO from '../../layout/seo/seo';
import CookieConsent from '../../layout/cookie-consent';

export function ListProducts(
  products,
  paginationConfig,
  activeCategory,
  seoConfig
) {
  const { totalPages, current, hasNextPage, hasPreviousPage, basePath } =
    paginationConfig;

  return (
    <main>
      <SEO title={seoConfig.title} description={seoConfig.description} />
      <CookieConsent />
      <Container fluid className="p-0">
        <Header />
        <HeroPrimary heroTitle="Literature" />
        <Filter activeCategory={activeCategory} />
        <Container fluid className="card-module">
          <Row className="justify-content-center">
            {products.map((product, i) => (
              <div key={i} className="mb-4 p-md-3 d-flex align-items-stretch">
                <Card product={product} />
              </div>
            ))}
          </Row>
        </Container>
        {paginationConfig && (
          <Pagination
            current={current}
            totalPages={totalPages}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            basePath={basePath}
          />
        )}
        <Logos />
        <Footer />
      </Container>
    </main>
  );
}
