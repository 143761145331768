import React from 'react';
import { graphql } from 'gatsby';
import { ListProducts } from '../shared/html/list-products-template';

function AllProducts({ data, pageContext }) {
  const { pageSize, current } = pageContext;

  const store = data.localSearchByCodeAndName.store;
  const allProducts = Object.values(store);

  const paginationStartIndex = (current - 1) * pageSize;
  const paginationEndIndex = paginationStartIndex + pageSize;
  const paginatedProducts = allProducts.slice(
    paginationStartIndex,
    paginationEndIndex
  );

  const seoConfig = {
    title: 'Literature',
  };

  return ListProducts(paginatedProducts, pageContext, 'All', seoConfig);
}

export default AllProducts;

export const query = graphql`
  query {
    localSearchByCodeAndName {
      index
      store
    }
  }
`;
