import React from 'react';

import { graphql, Link, useStaticQuery } from 'gatsby';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { getCategoriesFromProducts } from '../../services/category';
import { PAGE_CONFIG } from '../../shared/constants/page-config';

import './filter.scss';

export const Filter = (props) => {
  const { localSearchByCodeAndName } = useStaticQuery(graphql`
    query {
      localSearchByCodeAndName {
        store
      }
    }
  `);

  const allProducts = Object.values(localSearchByCodeAndName.store);
  const categories = ['All', ...getCategoriesFromProducts(allProducts)];
  const type = 'radio';

  return (
    <div className="bg-white">
      <div className="filter-module">
        <Container>
          <Row className="justify-content-center text-left text-sm-center">
            <Col>
              <Form>
                <fieldset>
                  <Form.Group>
                    <legend className="sr-only">
                      <h2 className="font-weight-extra-bold dark-grey-text text-center d-inline">
                        Filter
                      </h2>
                    </legend>
                    <div id="categories" key={`inline-${type}`}>
                      <h3 className="d-lg-inline">Categories:</h3>
                      {categories.map((category, index) => (
                        <Link
                          to={
                            category === 'All'
                              ? `${PAGE_CONFIG.baseUrl}`
                              : `${PAGE_CONFIG.baseUrl}/${category}`
                          }
                          key={index}
                        >
                          <Form.Check
                            inline
                            label={category}
                            name="group1"
                            type={type}
                            id={`inline-${type}-${index}`}
                            tabIndex="-1"
                            defaultChecked={props.activeCategory === category}
                          />
                        </Link>
                      ))}
                    </div>
                  </Form.Group>
                </fieldset>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
