import React from 'react';
import { Card, Row } from 'react-bootstrap';
import { callToOrderButton, downloadButton, fromStockButton } from '../buttons';

import '../buttons/buttons.scss';
import './cards.scss';

export const DuluxCard = ({ product }) => {
  return (
    <Card>
      <Card.Img variant="top" src={product.image} alt={product.name} />
      <Card.Body>
        <Card.Text as="div">
          <hr />
          <h3 className="h6 float-right text-muted">{product.category}</h3>
          <h4 className="h5 text-primary">{product.code}</h4>
          <p>{product.name}</p>
          <hr />
        </Card.Text>
        <Row className="mt-auto">
          {product.downloadUrl ? downloadButton(product.downloadUrl) : ''}

          {
            // TODO: refactor later
            !product.orderable
              ? ''
              : product.orderable.toLowerCase() === 'call to order'
              ? callToOrderButton()
              : product.orderable.toLowerCase() === 'from stock'
              ? fromStockButton(product)
              : ''
          }
        </Row>
      </Card.Body>
    </Card>
  );
};
